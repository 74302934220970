/////
/// Featured media
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-media
/// @author Ricardo Casares
/////

@import 'featured-media.variables';
@import 'featured-media.placeholders';

.wk-featured-media {
    @extend %wk-featured-media;
}

.wk-featured-media-container {
    background: $wk-featured-media-container-bg-color;
    padding: wk-rem(30px 0);
    padding-top: wk-rem(36px);

    .wk-featured-media {
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include wk-responsive-grid(
        (
            child: '.wk-featured-media',
            flex: true,
            gutters: $wk-featured-media-container-gutter,
            mobile-count: $wk-featured-media-container-mobile-items,
            tablet-count: $wk-featured-media-container-tablet-items,
            desktop-count: $wk-featured-media-container-desktop-items
        )
    );

    @include breakpoint($wk-target-tablet) {
        .wk-featured-media {
            &:nth-last-child(#{$wk-featured-media-container-tablet-items}) {
                margin-bottom: 0;
            }
        }
    }

    @include breakpoint($wk-target-desktop) {
        padding-top: wk-rem(36px);

        .wk-featured-media {
            margin-bottom: 0;
        }
    }
}
