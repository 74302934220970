/////
/// Feature
/// @since 0.1.0
/// @group wk-css-marketing.components.feature
/// @author Ryan Schoch
/////

@import 'feature.variables';
@import 'feature.placeholders';

.wk-feature {
    @extend %wk-feature;
}
