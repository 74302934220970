/////
/// Reset Other libraries styles for marketing
/// @since 0.1.0
/// @group wk-css-marketing.variables
/// @author [GPO](https://gpo.wolterskluwer.com/)
/////

abbr {
    &[title] {
        border-bottom: 0;
    }
}

figure {
    margin: 0;
    padding: 0;

    img {
        width: 100%;
    }
}
