/////
/// Icon header
/// @since 0.1.0
/// @group wk-css-marketing.components.icon-header
/// @author Ricardo Casares
/////

/// Declares properties for icon header component
%wk-icon-header {
    color: $wk-icon-header-header-text-color;
    display: block;
    margin: 0;
    margin-top: $wk-icon-header-margin-top;
    padding: $wk-icon-header-header-padding;
    position: relative;

    &::before,
    .wk-icon-header-icon {
        color: $wk-icon-header-icon-text-color;
        font-size: $wk-icon-header-icon-size;
        left: $wk-icon-header-icon-left;
        margin: 0;
        padding: $wk-icon-header-icon-padding;
        position: absolute;
        top: $wk-icon-header-icon-top;
        width: auto;
    }

    .wk-icon-header-icon {
        height: 45px;
        padding: wk-rem(8px 12px);
        width: 45px;
    }
}
