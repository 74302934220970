/////
/// Featured FAQ
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-faq
/// @author Andrii Lazurenko
/////

@import 'featured-faq.variables';

/// Declares properties for Feature FAQ
%wk-featured-faq {
    background-color: $wk-featured-faq-background-color;
    font-family: $wk-featured-faq-font-family;
    padding: $wk-featured-faq-padding;

    @include breakpoint($wk-target-desktop) {
        position: relative;
    }

    .wk-featured-faq-body {
        @include wk-clearfix;
        background-color: $wk-secondary-blue;
        padding: $wk-featured-faq-body-padding;
        position: relative;

        @include breakpoint($wk-target-tablet-desktop) {
            padding: $wk-featured-faq-body-padding-tablet-desktop;
            position: static;
        }

        @include breakpoint($wk-target-desktop) {
            padding: $wk-featured-faq-body-padding-desktop;
            width: $wk-featured-faq-body-width-desktop;
        }

        .wk-featured-faq-heading {
            color: $wk-featured-faq-heading-color;
            font-size: $wk-featured-faq-heading-font-size;
            margin-bottom: $wk-featured-fag-heading-margin-bottom;
            opacity: $wk-featured-fag-heading-opacity;
        }

        .wk-featured-faq-list {
            color: $wk-featured-faq-list-color;
            font-size: $wk-featured-faq-font-size;

            .wk-faq-title {
                cursor: pointer;
                padding: $wk-featured-faq-title-padding;

                + .wk-faq-description {
                    display: none;
                }

                .wk-faq-title-label {
                    opacity: $wk-featured-fag-heading-opacity;
                }

                .wk-faq-title-caption {
                    font-size: $wk-featured-faq-font-size;
                }

                &.active {
                    background-color: $wk-featured-faq-title-active-background-color;

                    + .wk-faq-description {
                        background-color: $wk-featured-faq-description-active-background-color;
                        color: $wk-featured-faq-description-active-color;
                        display: block;
                        margin: 0;
                        padding: $wk-featured-faq-title-padding;

                        @include breakpoint($wk-target-desktop) {
                            height: $wk-featured-faq-active-description-height-desktop;
                            padding: $wk-featured-faq-active-description-padding;
                            position: absolute;
                            right: 0;
                            top: $wk-featured-faq-active-description-top;
                            width: $wk-featured-faq-active-description-width-desktop;
                        }
                    }

                    .wk-faq-title-label {
                        color: $wk-featured-faq-title-label-active-color;
                    }

                    .wk-faq-title-caption {
                        color: $wk-featured-faq-title-caption-active-color;
                    }
                }

            }
        }

        .wk-featured-faq-button {
            background-color: inherit;
            left: 0;
            margin-bottom: 0;
            opacity: $wk-featured-faq-button-opacity;
            position: absolute;


            @include breakpoint($wk-target-tablet-desktop) {
                float: right;
                font-size: $wk-featured-faq-button-font-size;
                position: relative;
                top: $wk-featured-faq-button-top;
            }

            @include breakpoint($wk-target-tablet) {
                left: $wk-featured-faq-button-left;
            }
        }
    }

}
