/////
/// Product card
/// @since 0.1.0
/// @group wk-css-marketing.components.product-card
/// @author Andrii Lazurenko
/////

@import 'product-card.variables';
@import 'product-card.placeholders';
@import 'product-card.mixins';

.wk-product-card,
[wk-product-card] {
    @extend %wk-product-card;
    @include wk-product-card-colors($wk-product-card-default-text, $wk-product-card-default-background);

    &[wk-product-card*='featured'] {
        @extend %wk-product-card-desktop;
        @include wk-product-card-colors($wk-product-card-default-text-desktop, $wk-product-card-default-background-desktop);
    }
}

.wk-product-card-grid {
    [wk-product-card] {
        margin-bottom: $wk-product-card-grid-margin-bottom;
        padding: $wk-product-card-grid-padding;
    }

    @include wk-responsive-grid(
        (
            child: '[wk-product-card]',
            flex: true,
            gutters: $wk-product-card-grid-gutters,
            mobile-count: $wk-product-card-grid-mobile,
            tablet-count: $wk-product-card-grid-tablet,
            desktop-count: $wk-product-card-grid-desktop
        )
    );
}

.wk-product-card-grid-of-one {
    [wk-product-card] {
        margin-bottom: $wk-product-card-grid-one-margin-bottom;
        padding-top: $wk-product-card-grid-one-padding-top;

        .wk-product-card-body {
            padding: $wk-product-card-grid-one-body-padding;
        }

        .wk-product-card-label {
            left: auto;
            padding: $wk-product-card-grid-one-label-padding;
            right: $wk-product-card-grid-one-label-right-offset;
        }
    }
}

.wk-product-card-grid-of-two {
    [wk-product-card] {
        margin-bottom: $wk-product-card-grid-two-margin-bottom;

        .wk-product-card-body {
            padding: 0;
        }
    }

    @include wk-responsive-grid(
        (
            child: '[wk-product-card]',
            gutters: $wk-product-card-grid-two-gutters,
            mobile-count: $wk-product-card-grid-two-mobile,
            tablet-count: $wk-product-card-grid-two-tablet,
            desktop-count: $wk-product-card-grid-two-desktop
        )
    );
}

.wk-product-card-grid-of-three {
    [wk-product-card] {
        margin-bottom: $wk-product-card-grid-three-margin-bottom;
        padding: $wk-product-card-grid-three-padding;

        .wk-product-card-label {
            left: 0;
            right: auto;
        }

        .wk-product-card-body {
            padding: 0;
        }
    }

    @include wk-responsive-grid(
        (
            child: '[wk-product-card]',
            flex: true,
            gutters: $wk-product-card-grid-three-gutters,
            mobile-count: $wk-product-card-grid-three-mobile,
            tablet-count: $wk-product-card-grid-three-tablet,
            desktop-count: $wk-product-card-grid-three-desktop
        )
    );
}
