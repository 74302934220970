/////
/// Aspect Ratio
/// @since 0.1.0
/// @group wk-css-marketing.components.carousel
/// @author Andrii Lazurenko
/////

// More here http://nicolasgallagher.com/flexible-css-cover-images/
@import 'carousel.variables';

/// Makes cover image flexible so they can be scaled with a given ratio.
/// @param {Boolean} $max-height [false] - This flag should be set to true if target element
/// has max-height property.
/// @param {String} $ratio ['2:1'] - Aspect ratio (possible values '2:1', '3:1', '4:1')
/// @example scss - Usage
///
/// .wk-image {
///   @include wk-aspect-ratio('2:1')
///  }
///
/// @example css - Output
/// .wk-image {
///   background-position: 50%;
///   background-repeat: no-repeat;
///   background-size: cover;
///   display: block;
///   margin: 0 auto;
/// }
/// .wk-image::before {
///   content: '';
///   display: block;
/// }
///
/// @example scss - Usage
/// .wk-image {
///   max-height: 600px; //in this case $is-max-height should be set to true
///   @include wk-aspect-ratio('2:1', true)
///  }
///
/// @example css - Output
/// .wk-image {
///   background-position: 50%;
///   background-repeat: no-repeat;
///   background-size: cover;
///   display: block;
///   margin: 0 auto;
/// }
/// .wk-image::before {
///   content: '';
///   display: block;
///   padding-bottom: 50%;
/// }

@mixin wk-aspect-ratio($ratio: $wk-default-aspect-ratio, $is-max-height: false) {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin: 0 auto;

    &::before {
        content: '';
        display: block;
        @if $is-max-height {
            @if $ratio == '2:1' {
                padding-bottom: 50%;
            } @else if $ratio == '3:1' {
                padding-bottom: 33.3%;
            } @else if $ratio == '4:1' {
                padding-bottom: 25%;
            }
        }
    }
}
