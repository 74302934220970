/////
/// Feature
/// @since 0.1.0
/// @group wk-css-marketing.components.feature
/// @author Ryan Schoch
/////

/// Defines three component styles, call to action, promobox, and testimonial.
%wk-feature {
    margin-bottom: $wk-feature-margin-bottom;

    @include breakpoint($wk-target-desktop) {
        @include wk-clearfix;
        margin-top: $wk-feature-desktop-margin-top;
    }

    h1,
    .wk-feature-heading {
        background-color: $wk-feature-heading-background-color;
        color: $wk-feature-heading-color;
        font-family: $wk-feature-heading-font-family;
        font-size: $wk-feature-heading-font-size;
        font-style: $wk-feature-heading-font-style;
        font-weight: $wk-feature-heading-font-weight;
        margin-bottom: $wk-feature-heading-margin-bottom;
        margin-top: $wk-feature-heading-margin-top;
        padding-bottom: $wk-feature-heading-padding-bottom;
        padding-left: $wk-feature-heading-padding-left;
        padding-right: $wk-feature-heading-padding-right;
        padding-top: $wk-feature-heading-padding-top;

        @include breakpoint($wk-target-desktop) {
            float: $wk-feature-heading-float;
            font-size: $wk-feature-heading-desktop-font-size;
            font-weight: $wk-feature-heading-desktop-font-weight;
            margin-left: $wk-feature-heading-desktop-margin-left;
            margin-top: $wk-feature-heading-desktop-margin-top;
            max-width: $wk-feature-heading-desktop-max-width;
            padding-bottom: $wk-feature-heading-desktop-padding-bottom;
            padding-left: $wk-feature-heading-desktop-padding-left;
            padding-right: $wk-feature-heading-desktop-padding-right;
            padding-top: $wk-feature-heading-desktop-padding-top;
            position: $wk-feature-heading-desktop-position;
            z-index: $wk-feature-heading-z-index;
        }
    }

    figure {

        figcaption {
            z-index: $wk-feature-figcaption-desktop-z-index;
        }

        .wk-feature-figure-image {
            max-height: $wk-feature-figure-image-max-height;
            overflow: $wk-feature-figure-image-overflow;

            img {
                width: $wk-feature-figure-image-width;
            }
        }
    }

    blockquote {
        border-left: 0;
        margin: 0;
        padding: 0;
        quotes: $wk-feature-quotes;

        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }

    .wk-feature-body {
        background-color: $wk-feature-body-background-color;
        font-family: $wk-feature-body-font-family;
        padding-bottom: $wk-feature-body-padding-bottom;
        padding-left: $wk-feature-body-padding-left;
        padding-right: $wk-feature-body-padding-right;
        padding-top: $wk-feature-body-padding-top;
        position: $wk-feature-body-position;

        @include breakpoint($wk-target-desktop) {
            padding-bottom: $wk-feature-body-desktop-padding-bottom;
            padding-left: $wk-feature-body-desktop-padding-left;
            padding-right: $wk-feature-body-desktop-padding-right;
            padding-top: $wk-feature-body-desktop-padding-top;
            width: $wk-feature-body-desktop-width;
        }

        .wk-feature-list {
            @include wk-unstyle-list;

            > li {
                padding-bottom: $wk-feature-list-padding-bottom;
                padding-left: $wk-feature-list-padding-left;
                position: $wk-feature-list-position;
                word-break: $wk-feature-list-word-break;

                &::before {
                    content: $wk-feature-list-icon;
                    font-family: $wk-feature-icons-font;
                    margin-left: $wk-feature-list-icon-margin-left;
                    position: $wk-feature-list-icon-position;
                    top: $wk-feature-list-icon-top;
                }
            }
        }
    }

    footer {
        bottom: $wk-feature-footer-bottom;
        position: $wk-feature-footer-position;
        right: $wk-feature-footer-right;

        .wk-button,
        [wk-button] {
            margin-bottom: $wk-feature-footer-button-margin-bottom;
        }
    }

    &[wk-feature='call-to-action'] {
        margin-top: $wk-feature-call-to-action-margin-top;
        padding: $wk-feature-call-to-action-padding;
        position: $wk-feature-call-to-action-position;

        @include  breakpoint($wk-target-desktop) {
            margin-bottom: $wk-feature-call-to-action-desktop-margin-bottom;
            padding: $wk-feature-call-to-action-padding-desktop;
        }

        .wk-feature-heading {
            @include breakpoint($wk-target-desktop) {
                float: $wk-feature-call-to-action-heading-desktop-float;
                height: $wk-feature-call-to-action-heading-desktop-height;
                margin-left: $wk-feature-call-to-action-heading-margin-left;
                margin-top: $wk-feature-call-to-action-heading-margin-top;
                position: $wk-feature-call-to-action-heading-position;
                right: $wk-feature-call-to-action-heading-desktop-right;
                top: $wk-feature-call-to-action-heading-desktop-top;
                width: $wk-feature-call-to-action-heading-desktop-width;
            }
        }

        .wk-feature-body {
            font-weight: $wk-feature-body-font-weight;

            @include breakpoint($wk-target-desktop) {
                @include wk-clearfix;
                margin-right: $wk-feature-call-to-action-body-desktop-margin-right;
                margin-top: $wk-feature-call-to-action-body-desktop-margin-top;
                padding-right: $wk-feature-call-to-action-body-desktop-padding-right;
                position: $wk-feature-call-to-action-body-desktop-position;
                width: $wk-feature-call-to-action-body-desktop-width;

                address,
                blockquote,
                dl,
                ol,
                p,
                ul {
                    font-size: $wk-feature-body-font-size;
                }
            }
        }

        footer {
            @include breakpoint($wk-target-desktop) {
                bottom: $wk-feature-call-to-action-footer-desktop-bottom;
                position: $wk-feature-call-to-action-footer-desktop-position;
            }
        }
    }

    &[wk-feature='promobox'] {

        @include breakpoint($wk-target-desktop) {
            margin-top: $wk-feature-promobox-desktop-margin-top;
        }

        figure {
            @include breakpoint($wk-target-desktop) {
                float: $wk-feature-promobox-figure-desktop-float;
                width: $wk-feature-promobox-figure-desktop-width;
            }

            figcaption {
                background-color: $wk-feature-promobox-figcaption-background-color;
                color: $wk-feature-promobox-figcaption-color;
                font-family: $wk-feature-promobox-figcaption-font-family;
                font-size: $wk-feature-promobox-figcaption-font-size;
                font-style: $wk-feature-promobox-figcaption-font-style;
                font-weight: $wk-feature-promobox-figcaption-font-weight;
                padding-bottom: $wk-feature-promobox-figcaption-padding-bottom;
                padding-left: $wk-feature-promobox-figcaption-padding-left;
                padding-right: $wk-feature-promobox-figcaption-padding-right;
                padding-top: $wk-feature-promobox-figcaption-padding-top;

                @include breakpoint($wk-target-desktop) {
                    left: $wk-feature-promobox-figcaption-desktop-left;
                    position: $wk-feature-promobox-figcaption-desktop-position;
                }
            }
        }

        .wk-feature-body {
            @include breakpoint($wk-target-desktop) {
                float: $wk-feature-promobox-body-desktop-float;
            }
        }
    }

    &[wk-feature='testimonial'] {

        figure {
            float: $wk-feature-testimonial-figure-float;

            .wk-feature-figure-image {
                @include breakpoint($wk-target-desktop) {
                    @include wk-clearfix;
                    float: $wk-feature-testimonial-figure-float;
                    max-height: $wk-feature-testimonial-figure-image-desktop-max-height;
                    min-height: $wk-feature-testimonial-desktop-image-min-height;
                    min-width: $wk-feature-testimonial-desktop-image-min-width;
                    overflow-x: $wk-feature-testimonial-desktop-image-overflow-x;
                    width: $wk-feature-body-desktop-width;
                }
            }
        }

        figcaption,
        .wk-feature-heading {
            background-color: $wk-feature-testimonial-heading-background-color;
            margin-right: $wk-feature-testimonial-heading-margin-right;

            @include breakpoint($wk-target-desktop) {
                margin-top: $wk-feature-testimonial-margin-top;
            }

            blockquote {
                font-size: $wk-feature-heading-font-size;
                margin-bottom: $wk-feature-quote-margin-bottom;

                @include breakpoint($wk-target-desktop) {
                    font-size: $wk-feature-heading-desktop-font-size;
                }
            }

            .wk-feature-source {
                font-family: $wk-feature-source-font-family;
                font-size: $wk-feature-source-font-size;
                font-style: $wk-feature-source-font-style;
            }

            .wk-feature-source-meta {
                font-weight: $wk-feature-source-meta-font-weight;
            }
        }
    }
}
