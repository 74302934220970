/////
/// Feature product block
/// @since 0.1.0
/// @group wk-css-marketing.components.feature-product-block
/// @author Elena Donina
/////

/// Declares properties for featured product block component
%wk-featured-product-block {
    color: $wk-feature-product-block-font-color;
    margin-top: $wk-feature-product-grid-step;

    ul,
    li,
    h4,
    h5 {
        margin: 0;
        padding: 0;
    }

    header {
        background-color: $wk-feature-product-block-label-background-color;
        min-height: $wk-feature-product-grid-step;
        padding: $wk-feature-product-label-padding-top-bottom $wk-feature-product-padding-left-right;

        @include breakpoint($wk-target-tablet-desktop) {
            margin-right: $wk-feature-product-grid-step;
            padding-left: $wk-feature-product-grid-step;
            padding-right: $wk-feature-product-grid-step;
        }

        h4 {
            color: $wk-feature-product-block-font-color;
            font-weight: $wk-feature-product-h4-font-weight;
        }
    }

    .wk-featured-product-block-body {
        @include wk-clearfix;

        background-color: $wk-feature-product-block-background-color;
        padding: 0 $wk-feature-product-padding-left-right $wk-feature-product-padding-bottom;
        position: relative;

        @include breakpoint($wk-target-tablet-desktop) {
            margin-left: $wk-feature-product-grid-step;
            padding: 0 $wk-feature-product-padding-left-right-desktop $wk-feature-product-padding-bottom-desktop;
        }

        li {
            @include wk-target-icons {
                font-size: $wk-feature-product-block-font-size-icon;
                margin-right: $wk-feature-product-block-icon-margin-right;
                position: relative;
                top: $wk-feature-product-block-icon-alignment;
            }

            line-height: $wk-feature-product-li-line-height;
            list-style-type: none;
            margin-bottom: $wk-feature-product-li-margin-bottom;

            @include breakpoint($wk-target-tablet-desktop) {
                @include wk-target-icons {
                    font-size: $wk-feature-product-block-font-size-icon-desktop;
                    top: $wk-feature-product-block-icon-alignment-desktop;
                }

                font-size: $wk-feature-product-block-font-size-desktop;
            }
        }

        h5 {
            color: $wk-feature-product-block-font-color;
            font-size: $wk-feature-product-block-font-size-desktop;
            font-weight: $wk-feature-product-h5-font-weight;
            line-height: $wk-feature-product-grid-step;
        }

        button,
        .wk-button {
            &.wk-call-to-action {
                margin-bottom: 0;
            }
        }

        .wk-call-to-action {
            bottom: 0;
            position: absolute;
            right: 0;
        }
    }
}
