/////
/// Variables
/// @since 0.1.0
/// @group wk-css-marketing.placeholders
/// @author Ricardo Casares
/////

/// Make your iframes, objects, embeds responsive
%wk-responsive-embed {
    height: 0;
    margin-bottom: 5px;
    padding-bottom: 56.25%;
    padding-top: wk-rem(25px);
    position: relative;

    iframe,
    object,
    embed {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
