/////
/// Page Intro
/// @since 0.2.0
/// @group wk-css-marketing.components.page-intro
/// @author Andrii Lazurenko
/////

@import 'page-intro.variables';
@import 'page-intro.placeholders';

.wk-page-intro {
    @extend %wk-page-intro;
}
