/////
/// Feature carousel
/// @since 0.0.1
/// @group wk-css-marketing.components.feature-carousel
/// @author Ricardo Casares
/////

/// Set feature carousel color
/// @param $color - Default background color
@mixin wk-feature-carousel-colors($color) {
    $color: rgba($color, .8);

    .wk-carousel-body {
        .wk-carousel-slide {
            .wk-carousel-slide-description {
                background-color: $color;
            }
        }
    }
}
