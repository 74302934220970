/////
/// Featured contact
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-contact
/// @author Ricardo Casares
/////

/// Customizes a featured contact card
///
/// @param {color} $txt Text color
/// @param {color} $bg  Background color
@mixin wk-featured-contact-colors($txt: $wk-featured-contact-default-text, $bg: $wk-featured-contact-default-bg) {
    background-color: $bg;
    color: $txt;

    a,
    .wk-featured-contact-heading {
        color: $txt;
    }
}
