/////
/// Video player
/// @since version 0.1.0
/// @group wk-css-marketing.components.video-player
/// @author [GPO](https://gpo.wolterskluwer.com/)
/////

@import 'video_player.placeholders';

.wk-video-player {
    @extend %wk-video-player;
}
