/////
/// Featured contact
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-contact
/// @author Ricardo Casares
/////

/// Defines featured contact element styles
%wk-featured-contact {
    font-family: $wk-featured-contact-font-family;
    padding: $wk-featured-contact-padding;

    figure {
        display: table;
        margin: $wk-featured-contact-figure-margin;

        img,
        figcaption {
            display: table-cell;
        }

        img {
            min-width: $wk-featured-contact-image-min-width;
            width: $wk-featured-contact-image-width;
        }

        figcaption {
            padding: $wk-featured-contact-caption-padding;
            vertical-align: middle;
        }
    }

    .wk-featured-contact-heading {
        margin: $wk-featured-contact-heading-margin;
    }

    .wk-featured-contact-body {
        font-weight: $wk-featured-contact-body-weight;
        margin: $wk-featured-contact-body-margin;
    }

    footer {
        .wk-featured-contact-list {
            @include wk-unstyle-list;

            li {
                display: block;
            }
        }

        @include wk-target-icons {
            margin: $wk-featured-contact-list-icon-margin;
        }
    }

    @include wk-featured-contact-colors;
}
