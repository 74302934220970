/////
/// Featured media
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-media
/// @author Ricardo Casares
/////

/// Defines a featured media element styles
%wk-featured-media {
    @extend %wk-feature-card;

    .wk-icon-header {
        @extend %wk-icon-header;
        @include wk-icon-header-colors($wk-featured-media-icon-bg-color, none);
        margin-bottom: $wk-featured-media-icon-header-margin-bottom;
        margin-top: 0;
        padding: 0;

        &::before,
        .wk-icon-header-icon {
            left: 0;
            top: $wk-featured-media-icon-top;
        }
    }

    .wk-featured-media-body {
        color: $wk-featured-media-body-text-color;
        padding: 0;
    }
}
