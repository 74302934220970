/////
/// Buttons
/// @since 0.1.0
/// @group wk-css-marketing.components.buttons
/// @author Ricardo Casares
/////

@import 'buttons.variables';
@import 'wk-components/buttons/buttons.mixins';

.wk-button,
[wk-button] {
    &[wk-button*='hollow'] {
        @include wk-button-icon($wk-buttons-hollow-icon-placement, $wk-buttons-hollow-icon-bg-color);

        background: $wk-buttons-hollow-bg-color;
        color: $wk-buttons-hollow-color;

        &:hover {
            &:not([disabled]) {
                @include wk-button-icon($wk-buttons-hollow-icon-placement, $wk-buttons-hollow-icon-bg-color-hover);

                background: none;
                color: $wk-buttons-hollow-color-hover;
            }
        }
    }
}
