/////
/// Product Card
/// @since 0.1.0
/// @group wk-css-marketing.components.product-card
/// @author Andrii Lazurenko
/////

/// Declares properties for product card component
%wk-product-card {
    background: $wk-product-card-background;
    padding: $wk-product-card-padding;
    position: relative;

    .wk-product-card-label {
        font-family: $wk-product-card-label-font;
        font-style: $wk-product-card-label-font-style;
        padding: $wk-product-card-label-padding;
        position: absolute;
        right: 0;
        top: 0;
    }

    .wk-product-card-content {
        margin: $wk-product-card-content-margin;
    }

    .wk-product-card-body {
        font-family: $wk-product-card-body-font;
        margin-bottom: 60px;
    }

    .wk-product-card-heading {
        margin: $wk-product-card-heading-margin;
    }

    .wk-product-card-description {
        font-weight: $wk-product-card-description-font-weight;
    }

    .wk-product-card-button {
        bottom: 0;
        margin: 0;
        position: absolute;
        right: 0;
        width: auto;

        &:hover {
            &:not([disabled]) {
                position: absolute;
            }
        }
    }

    .wk-product-card-price-tag {
        bottom: $wk-product-card-price-tag-bottom-offset;
        color: $wk-product-card-price-tag-color;
        font-family: $wk-product-card-price-tag-font;
        font-size: $wk-product-card-price-tag-font-size;
        font-style: $wk-product-card-price-tag-font-style;
        position: absolute;
    }
}

@include breakpoint($wk-target-tablet-desktop) {
    /// Declares properties for the desktop view of product card
    %wk-product-card-desktop {
        .wk-product-card-label {
            left: $wk-product-card-label-left-offset;
            padding: $wk-product-card-label-padding-desktop;
            right: $wk-product-card-label-right-offset;
        }

        .wk-product-card-content {
            @include container;
        }

        figure {
            @include span(4 of 12 1);
        }

        .wk-product-card-body {
            @include span(8 of 12 1);
            @include last;

            padding-right: $wk-prdocut-card-body-padding;
        }

        &[wk-product-card*='price-tag'] {
            .wk-product-card-body {
                margin: 0;
            }

            .wk-product-card-price-tag {
                background: $wk-product-card-price-tag-desktop-background;
                bottom: $wk-product-card-price-tag-desktop-bottom-offset;
                color: $wk-product-card-price-tag-desktop-color;
                padding: $wk-product-card-price-tag-desktop-padding;
                position: absolute;
                right: $wk-product-card-price-tag-desktop-right-offset;
                top: $wk-product-card-price-tag-desktop-top-offset;
            }
        }
    }
}
