/////
/// Carousel
/// @since 0.1.0
/// @group wk-css-marketing.components.carousel
/// @author Andrii Lazurenko
/////

@import 'carousel.variables';
@import 'aspect-ratio.mixins';
@import 'swiper';

/// Declares properties for feature carousel
%wk-carousel {
    overflow: hidden;
    position: relative;

    .wk-carousel-body {
        height: $wk-carousel-body-height;
        @include breakpoint($wk-target-tablet-desktop) {
            height: auto;
        }

        @include breakpoint($wk-target-tablet) {
            height: $wk-carousel-slide-max-height-tablet;
        }

        @include breakpoint($wk-target-desktop) {
            height: $wk-carousel-slide-max-height-desktop;
        }

        .wk-carousel-slide {
            background-color: $wk-carousel-slide-background-color;
            position: relative;

            @include breakpoint($wk-target-tablet) {
                max-height: $wk-carousel-slide-max-height-tablet;
            }

            @include breakpoint($wk-target-desktop) {
                max-height: $wk-carousel-slide-max-height-desktop;
            }

            .wk-carousel-slide-image-wrapper {
                height: 100%;
                margin: 0;
            }

            .wk-carousel-slide-image {
                @include wk-aspect-ratio($ratio: '2:1');
                height: 100%;
            }

            .wk-carousel-slide-description {
                background-color: $wk-carousel-slide-description-background-color;
                bottom: 0;
                color: $wk-carousel-slide-description-color;
                display: block;
                font-family: $wk-carousel-slide-description-font-family;
                height: 100%;
                position: absolute;
                width: 100%;

                figcaption {
                    font-size: $wk-carousel-slide-description-figcaption-font-size;
                    font-style: $wk-carousel-slide-description-figcaption-font-style;
                    padding: $wk-carousel-slide-description-figcaption-padding;
                }

                @include breakpoint($wk-target-tablet-desktop) {
                    height: $wk-carousel-slide-description-figcaption-height-tablet-desktop;

                    figcaption {
                        font-size: $wk-carousel-slide-description-figcaption-font-size-tablet-desktop;
                    }
                }

                @include breakpoint($wk-target-tablet) {
                    figcaption {
                        padding: $wk-carousel-slide-description-figcaption-padding-tablet;
                    }
                }

                @include breakpoint($wk-target-desktop) {
                    left: $wk-carousel-slide-description-left-desktop;
                    width: $wk-carousel-slide-description-width-desktop;

                    figcaption {
                        padding: $wk-carousel-slide-description-figcaption-padding-desktop;
                    }
                }

            }

            .wk-carousel-slide-button-bar {
                background-color: transparent;
                bottom: $wk-carousel-slide-button-bar-bottom;
                position: absolute;
                width: 100%;

                .wk-carousel-slide-button {
                    position: static;

                    &::before {
                        width: $wk-carousel-slide-button-arrow-width;
                    }
                }

                @include breakpoint($wk-target-tablet-desktop) {
                    background-color: $wk-carousel-slide-button-bar-background-color;
                    bottom: 0;

                    .wk-carousel-slide-button {
                        float: right;
                        position: static;
                        width: $wk-carousel-slide-button-width-desktop;
                    }
                }

                @include breakpoint($wk-target-desktop) {
                    width: $wk-carousel-slide-button-bar-width-desktop;
                }
            }

        }

    }

    .wk-carousel-pagination {
        height: $wk-carousel-pagination-height;
        position: absolute;
        text-align: center;
        text-indent: $wk-carousel-pagination-text-indent;
        z-index: $wk-carousel-pagination-z-index;

        .swiper-pagination-bullet {
            background-color: $wk-carousel-pagination-bullet-background-color;
            border-radius: inherit;
            height: $wk-carousel-pagination-swiper-pagination-bullet-height;
            margin: $wk-carousel-pagination-swiper-pagination-bullet-margin;
            width: $wk-carousel-pagination-swiper-pagination-bullet-width;

            @include breakpoint($wk-target-tablet-desktop) {
                height: $wk-carousel-pagination-swiper-pagination-bullet-height-tablet-desktop;
                margin: $wk-carousel-pagination-swiper-pagination-bullet-margin-tablet-desktop;
                width: $wk-carousel-pagination-swiper-pagination-bullet-width-tablet-desktop;
            }

            &-active {
                background-color: $wk-carousel-pagination-bullet-active-background-color;
            }
        }

        @include breakpoint($wk-target-tablet-desktop) {
            text-align: left;
            text-indent: $wk-carousel-pagination-text-indent-tablet-desktop;
            top: $wk-carousel-pagination-top-desktop;
        }

    }

    .wk-carousel-nav-prev,
    .wk-carousel-nav-next {
        background-image: none;
        display: none;
        font-family: $wk-carousel-nav-font-family;

        @include breakpoint($wk-target-desktop) {
            display: block;
            height: $wk-carousel-nav-height-desktop;
            position: absolute;
            top: $wk-carousel-nav-top-desktop;
            width: $wk-carousel-nav-width-desktop;
        }

        &::before {
            background-color: $wk-carousel-nav-button-background-color;
            color: $wk-carousel-nav-button-color;
            display: inline-block;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            width: 100%;
            zoom: $wk-carousel-nav-button-zoom;
        }
    }

    .wk-carousel-nav-prev {
        left: 0;

        &::before {
            content: $wk-carousel-nav-prev-content;
            text-indent: $wk-carousel-nav-prev-text-indent;
        }
    }

    .wk-carousel-nav-next {
        right: 0;

        &::before {
            content: $wk-carousel-nav-next-content;
            text-indent: $wk-carousel-nav-next-text-indent;
        }
    }
}
