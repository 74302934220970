/////
/// Featured Hero Shop
/// @since 0.2.0
/// @group wk-css-marketing.components.featured-hero-shop
/// @author Andrii Lazurenko
/////

@import 'featured-hero-shop.variables';
@import 'featured-hero-shop.placeholders';

.wk-featured-hero-shop {
    @extend %wk-featured-hero-shop;
}


