/////
/// Hero
/// @since 0.2.0
/// @group wk-css-marketing.components.hero
/// @author Andrii Lazurenko
/////
@import '../carousel/aspect-ratio.mixins';

/// Defines properties for Hero component
%wk-hero {

    @include breakpoint($wk-target-desktop) {
        padding-bottom: $wk-hero-padding-bottom;
    }

    .wk-hero-container {
        height: $wk-hero-container-height;
        position: relative;

        @include breakpoint($wk-target-tablet-desktop) {
            height: $wk-hero-container-height-tablet-desktop;
        }

        .wk-hero-image-wrapper {
            height: 100%;

            .wk-hero-image {
                @include wk-aspect-ratio('2:1');
                height: 100%;
            }
        }

        .wk-hero-header {
            position: absolute;
            top: 0;
            width: 100%;

            .wk-header {
                background-color: transparent;

                @include breakpoint($wk-target-desktop) {
                    padding: $wk-hero-wk-header-container-padding;
                }

                .wk-nav,
                .wk-more-menu {
                    color: $wk-hero-wk-nav-color;

                    a {
                        color: currentColor;
                    }

                    span {
                        &::before {
                            color: currentColor;
                        }
                    }
                }
            }
        }

        .wk-hero-details-container {
            @include container;
            height: 100%;
            position: relative;
            top: -100%;
            @include breakpoint($wk-target-tablet) {
                height: $wk-hero-container-height-tablet-desktop;
            }
        }

        .wk-hero-details {
            background-color: $wk-hero-details-background-color;
            height: $wk-hero-details-height;
            padding-bottom: $wk-hero-details-padding-bottom;
            padding-top: $wk-hero-details-padding-top;
            position: absolute;
            top: $wk-hero-details-top;
            width: 100%;

            @include breakpoint($wk-target-tablet) {
                height: $wk-hero-details-height-tablet;
                padding-left: $wk-hero-details-padding-left-tablet;
                padding-top: $wk-hero-details-padding-top-tablet;
                top: $wk-hero-details-top-tablet;
            }

            @include breakpoint($wk-target-desktop) {
                background-color: transparent;
                bottom: 0;
                height: auto;
                padding-bottom: 0;
                padding-left: $wk-hero-details-padding-left-desktop;
                top: 0;

                .caption-container,
                .caption-description {
                    display: inline-block;
                }
            }

            .caption-container {
                @include wk-clearfix;

                @include breakpoint($wk-target-desktop) {
                    bottom: 0;
                    position: absolute;
                    width: $wk-hero-caption-container-width-desktop;
                    z-index: $wk-hero-caption-container-z-index-desktop;
                }

                .caption {
                    color: $wk-hero-caption-color;
                    padding: $wk-hero-caption-padding;

                    @include breakpoint($wk-target-desktop) {
                        background-color: $wk-hero-caption-background-color-desktop;
                        padding: $wk-hero-caption-padding-desktop;
                    }

                    h5 {
                        color: currentColor;
                        font-family: $wk-hero-caption-h5-font-family;
                        font-size: $wk-hero-caption-h5-font-size;
                        font-style: italic;
                        margin-top: 0;
                    }

                    p {
                        font-family: $wk-hero-caption-p-font-family;
                        padding-top: $wk-hero-caption-p-padding-top;
                    }
                }

                .caption-button {
                    bottom: 0;
                    margin-bottom: 0;
                    position: absolute;

                    @include breakpoint($wk-target-tablet) {
                        right: 0;
                    }

                    @include breakpoint($wk-target-desktop) {
                        float: right;
                        position: relative;
                        right: 0;
                    }
                }
            }

            .caption-description {
                color: $wk-hero-caption-description-color;
                font-family: $wk-hero-caption-caption-description-font-family;
                font-style: italic;
                padding: $wk-hero-caption-caption-description-padding;

                @include breakpoint($wk-target-desktop) {
                    background-color: $wk-hero-caption-description-background-color-desktop;
                    bottom: $wk-hero-caption-description-bottom-desktop;
                    color: $wk-hero-caption-description-color-desktop;
                    min-height: $wk-hero-caption-description-min-height-desktop;
                    padding-left: $wk-hero-caption-description-padding-left-desktop;
                    padding-right: $wk-hero-caption-description-padding-right-desktop;
                    position: absolute;
                    right: $wk-hero-caption-description-right-desktop;
                    vertical-align: top;
                    width: $wk-hero-caption-description-width-desktop;
                }

                p {
                    line-height: $wk-hero-caption-description-p-line-height;
                }
            }
        }
    }
}
