/////
/// Hero Carousel
/// @since 0.2.0
/// @group wk-css-marketing.components.hero-carousel
/// @author Andrii Lazurenko
/////

///Defines properties for Hero Carousel component
%wk-hero-carousel {
    .wk-hero-container {
        position: relative;

        .wk-hero-header {
            position: absolute;
            width: 100%;
            z-index: $wk-hero-carousel-header-z-index;

            .wk-header {
                background-color: $wk-hero-carousel-header-wk-header-background-color;
                position: relative;

                @include breakpoint($wk-target-desktop) {
                    padding: $wk-hero-carousel-header-wk-header-padding-desktop;
                }
            }

            .wk-nav,
            .wk-more-menu {
                color: $wk-hero-carousel-header-nav-color;

                a {
                    color: currentColor;
                }

                span {
                    &::before {
                        color: currentColor;
                    }
                }
            }
        }
    }

    .wk-carousel {
        .wk-carousel-pagination {
            @include breakpoint($wk-target-tablet-desktop) {
                position: absolute;
                right: 50%;
                top: 52%;
                width: 50%;
            }

            @include breakpoint($wk-desktop-max-width) {
                margin-left: auto;
                margin-right: auto;
                padding-left: $wk-hero-carousel-wk-carousel-pagination-padding-left-desktop-max-width;
                right: 0;
                text-indent: 0;
                width: $wk-desktop-max-width;
            }
        }

        .wk-carousel-body {
            .wk-carousel-slide {
                .wk-carousel-slide-description {
                    @include breakpoint($wk-target-desktop) {
                        left: auto;
                        max-width: $wk-desktop-max-width / 2;
                        right: 50%;
                    }

                    figcaption {
                        padding-top: $wk-hero-carousel-wk-carousel-slide-description-padding-top;

                        @include breakpoint($wk-target-tablet) {
                            padding-top: $wk-hero-carousel-wk-carousel-slide-description-padding-top-tablet;
                        }

                        @include breakpoint($wk-target-desktop) {
                            padding-top: $wk-hero-carousel-wk-carousel-slide-description-padding-top-desktop;
                        }
                    }
                }

                .wk-carousel-slide-button-bar {
                    .wk-carousel-slide-button {
                        @include breakpoint($wk-target-tablet) {
                            bottom: 0;
                            position: absolute;
                            right: 0;
                        }
                    }
                    @include breakpoint($wk-target-desktop) {
                        max-width: $wk-desktop-max-width / 2;
                        right: 50%;
                    }
                }
            }
        }
    }
}
