/////
/// Feature carousel
/// @since 0.0.1
/// @group wk-css-marketing.components.feature-carousel
/// @author Ricardo Casares
/////

/// Defines styles and properties for the feature-carousel component
%wk-feature-carousel {
    @extend %wk-carousel;

    .wk-carousel-body {
        max-height: $wk-feature-carousel-max-height;

        .wk-carousel-slide {
            background: none;
            height: auto;

            @include breakpoint($wk-target-desktop) {
                height: auto;
            }

            .wk-carousel-slide-image-wrapper {
                height: 100%;
                width: 100%;

                @include breakpoint($wk-target-desktop) {
                    float: left;
                    height: $wk-feature-carousel-slide-image-wrapper-desktop-height;
                    width: $wk-feature-carousel-slide-image-wrapper-desktop-width;
                }
            }

            .wk-carousel-slide-description {
                padding: 0;
                width: 100%;

                figcaption {
                    background: none;
                    font-family: $wk-feature-carousel-slide-caption-font;
                    font-size: $wk-feature-carousel-slide-caption-font-size;
                    font-style: $wk-feature-carousel-slide-caption-font-style;
                    left: 0;
                    padding: $wk-carousel-slide-figcaption-padding;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                @include breakpoint($wk-target-desktop) {
                    bottom: auto;
                    left: auto;
                    right: 0;
                    top: 50%;
                    width: 90%;
                }
            }

            .wk-carousel-slide-button-bar {
                background: transparent;
                bottom: 0;
                width: 100%;

                .wk-carousel-slide-button {
                    background: transparent;
                    bottom: 0;
                    margin-bottom: 0;
                    position: absolute;
                    right: 0;
                    width: auto;
                }
            }
        }
    }

    .wk-carousel-pagination {
        left: 25px;
        text-indent: 0;
        width: auto;

        @include breakpoint($wk-target-desktop) {
            left: 65px;
        }
    }
}
