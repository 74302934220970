/////
/// Feature card
/// @since 0.1.0
/// @group wk-css-marketing.components.feature-card
/// @author Ricardo Casares
/////

@import 'feature-card.variables';
@import 'feature-card.placeholders';

.wk-feature-card {
    @extend %wk-feature-card;
}

.wk-feature-card-container {
    @include wk-responsive-grid(
        (
            child: '.wk-feature-card',
            flex: true,
            gutters: $wk-feature-card-container-gutter,
            mobile-count: $wk-feature-card-container-mobile-items,
            tablet-count: $wk-feature-card-container-tablet-items,
            desktop-count: $wk-feature-card-container-desktop-items
        )
    );
}
