/////
/// Featured Hero Shop
/// @since 0.2.0
/// @group wk-css-marketing.components.featured-hero-shop
/// @author Andrii Lazurenko
/////

@import '../hero/hero.placeholders';

%wk-featured-hero-shop {
    @extend %wk-hero;

    margin-bottom: $wk-feature-hero-shop-margin-bottom;
    padding-bottom: $wk-feature-hero-shop-padding-bottom;

    @include breakpoint($wk-target-desktop) {
        margin-bottom: $wk-feature-hero-shop-margin-bottom-desktop;
    }

    .wk-hero-container {
        .wk-hero-details {
            background-color: $wk-feature-hero-shop-wk-hero-details-background-color;

            @include breakpoint($wk-target-desktop) {
                background-color: $wk-feature-hero-shop-wk-hero-details-background-color-desktop;
            }

            .caption-container {
                .caption {
                    @include breakpoint($wk-target-desktop) {
                        background-color: $wk-feature-hero-shop-caption-background-color-desktop;
                    }
                }
            }
        }

        .wk-shop-bar {
            background-color: $wk-feature-hero-shop-wk-shop-bar-background-color;
            bottom: $wk-feature-hero-shop-wk-shop-bar-bottom;
            position: absolute;
            width: $wk-feature-hero-shop-wk-shop-bar-width;
        }

        .wk-shop-bar-container {

            @include breakpoint($wk-target-desktop) {
                margin: 0 auto;
                max-width: $wk-feature-hero-shop-bar-container-max-width-desktop;
            }

            ul {
                background-color: $wk-feature-hero-shop-wk-shop-bar-background-color;
                padding-left: $wk-feature-hero-shop-wk-shop-bar-padding-left;

                @include breakpoint($wk-target-desktop) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                li {
                    list-style: none;

                    p {
                        color: $wk-feature-hero-shop-wk-shop-bar-p-color;
                        font-family: $wk-feature-hero-shop-wk-shop-bar-p-font-family;
                        line-height: $wk-feature-hero-shop-wk-shop-bar-p-line-height;
                        padding-left: $wk-feature-hero-shop-wk-shop-bar-p-padding-left;


                        @include breakpoint($wk-target-desktop) {
                            line-height: $wk-feature-hero-shop-wk-shop-bar-p-line-height-desktop;
                            padding: $wk-feature-hero-shop-wk-shop-bar-p-padding-desktop;
                        }
                    }

                    span {
                        color: $wk-feature-hero-shop-wk-shop-bar-span-color;
                        font-size: $wk-feature-hero-shop-wk-shop-bar-span-font-size;
                        position: $wk-feature-hero-shop-wk-shop-bar-span-position;
                        top: $wk-feature-hero-shop-wk-shop-bar-span-top;
                    }
                }
            }
        }
    }
}
