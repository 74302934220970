/////
/// Feature product block
/// @since 0.1.0
/// @group wk-css-marketing.components.feature-product-block
/// @author Elena Donina
/////

@import 'featured-product-block.variables';
@import 'featured-product-block.placeholders';

.wk-featured-product-block {
    @extend %wk-featured-product-block;
}
