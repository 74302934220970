/////
/// Icon header
/// @since 0.1.0
/// @group wk-css-marketing.components.icon-header
/// @author Ricardo Casares
/////

/// Sets the feature card header and icon background colors
///
/// @param $icon-bg [wk-primary-color(wkGray, tint1)] - Icon background
/// @param $header-bg [wk-primary-color(wkGray)] - Header background
/// @param $icon-text [wk-primary-color(wkWhite)] - Icon text color
/// @param $header-text [wk-primary-color(wkWhite)] - Header text color
@mixin wk-icon-header-colors(
    $icon-bg: $wk-icon-header-icon-bg-color,
    $header-bg: $wk-icon-header-header-bg-color,
    $icon-text: $wk-icon-header-icon-text-color,
    $header-text: $wk-icon-header-header-text-color
) {
    background: $header-bg;
    color: $header-text;

    &::before,
    .wk-icon-header-icon {
        background-color: $icon-bg;
        color: $icon-text;
    }

    .wk-icon-header-icon {
        wk-icon {
            fill: $icon-text;
        }
    }
}
