/////
/// Feature card
/// @since 0.1.0
/// @group wk-css-marketing.components.feature-card
/// @author Ricardo Casares
/////


/// Declares properties for feature card component
%wk-feature-card {
    margin-top: wk-rem(36px);

    .wk-feature-card-body {
        padding: $wk-feature-card-body-padding;
    }
}
