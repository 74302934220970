/////
/// USP
/// @since 0.1.0
/// @group wk-css-marketing.components.usp
/// @author Elena Donina
/////

@import 'usp.variables';

#{$wk-usp-card-name}-group {

    @include wk-responsive-grid(
        (
            child: $wk-usp-card-name,
            flex: $wk-usp-card-flex,
            gutters: $wk-usp-card-gutters,
            mobile-count: $wk-usp-card-mobile-count,
            tablet-count: $wk-usp-card-tablet-count,
            desktop-count: $wk-usp-card-desktop-count
        )
    );
}

#{$wk-usp-card-name} {

    font-family: $wk-usp-font-family;
    padding-bottom: $wk-usp-card-horizontal-gutter;

    &#{$wk-usp-card-name}-centered,
    #{$wk-usp-card-name}-group-centered & {
        text-align: center;
    }

    &#{$wk-usp-card-name}-image-left,
    #{$wk-usp-card-name}-group-image-left &,
    &#{$wk-usp-card-name}-image-right,
    #{$wk-usp-card-name}-group-image-right & {
        .wk-card-content {
            position: relative;

            .wk-card-image {
                position: absolute;
                text-align: center;
                top: 0;

                img {
                    width: 100%;
                }
            }
        }
    }

    &#{$wk-usp-card-name}-image-left,
    #{$wk-usp-card-name}-group-image-left & {
        .wk-card-content {
            margin-left: calc(#{$wk-usp-image-width} + #{$wk-usp-image-gutter});

            .wk-card-image {
                left: calc((#{$wk-usp-image-width} + #{$wk-usp-image-gutter}) * -1);
            }

            @include breakpoint($wk-target-tablet-desktop) {
                margin-left: calc(#{$wk-usp-image-width-tablet-desktop} + #{$wk-usp-image-gutter-tablet-desktop});

                .wk-card-image {
                    left: calc((#{$wk-usp-image-width-tablet-desktop} + #{$wk-usp-image-gutter-tablet-desktop}) * -1);
                }
            }
        }
    }

    &#{$wk-usp-card-name}-image-right,
    #{$wk-usp-card-name}-group-image-right & {
        .wk-card-content {
            margin-right: calc(#{$wk-usp-image-width} + #{$wk-usp-image-gutter});

            .wk-card-image {
                right: calc((#{$wk-usp-image-width} + #{$wk-usp-image-gutter}) * -1);
            }

            @include breakpoint($wk-target-tablet-desktop) {
                margin-right: calc(#{$wk-usp-image-width-tablet-desktop} + #{$wk-usp-image-gutter-tablet-desktop});

                .wk-card-image {
                    right: calc((#{$wk-usp-image-width-tablet-desktop} + #{$wk-usp-image-gutter-tablet-desktop}) * -1);
                }
            }
        }
    }

    .wk-card-image {
        wk-icon {
            fill: wk-primary-color(wkBlue);
        }

        img {
            display: inline-block;
            height: auto;
            vertical-align: top;
        }

        @include breakpoint($wk-target-tablet-desktop) {
            img {
                margin-top: 3px;
            }
        }
    }

    .wk-card-title {
        @include wk-font-style($wk-usp-title-font-size, $wk-usp-title-line-height);
        color: $wk-usp-title-font-color;
        margin-bottom: $wk-usp-title-margin-bottom;

        @include breakpoint($wk-target-tablet-desktop) {
            @include wk-font-style($wk-usp-title-font-size-tablet-desktop, $wk-usp-title-line-height-tablet-desktop);
        }
    }

    .wk-card-description {
        @include wk-font-style($wk-usp-font-size, $wk-usp-line-height);

        color: wk-primary-color(wkGray);

        @include breakpoint($wk-target-tablet-desktop) {
            @include wk-font-style($wk-usp-font-size, $wk-usp-line-height-tablet-desktop);
        }
    }

    a,
    a & {
        .wk-card-title {
            color: $wk-usp-title-font-color-link;
        }

        &:hover {
            .wk-card-title,
            .wk-card-image {
                color: $wk-usp-title-font-color-link-hover;
            }
        }
    }
}
