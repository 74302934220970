/////
/// Video player
/// @since version 0.1.0
/// @group wk-css-marketing.components.video-player
/// @author [GPO](https://gpo.wolterskluwer.com/)
/////

@import 'video-player.variables';

%wk-video-player {
    font-family: $wk-video-player-font-family;
    font-size: $wk-video-player-font-size;

    h1,
    .wk-video-player-title {
        color: $wk-video-player-header-color;
        font-size: $wk-video-player-header-font-size;
        font-weight: $wk-video-player-header-font-weight;
    }

    .wk-video-player-description {
        color: $wk-video-player-body-color;
    }

    p,
    .wk-video-player-description {
        font-size: $wk-video-player-description-font-size;
        font-weight: $wk-video-player-description-font-weight;
        margin-bottom: $wk-video-player-description-margin-bottom;
    }

    a.wk-video-player-caption {
        @include wk-link($wk-video-player-caption-color, $wk-video-player-caption-hover-color);

        display: block;
    }

    // responsive wrapper for video-like objects
    .wk-video-player-wrapper {
        @extend %wk-responsive-embed;
    }
}
