/////
/// Product Card
/// @since 0.1.0
/// @group wk-css-marketing.components.product-card
/// @author Andrii Lazurenko
/////

/// Defines the product card colors
/// @param $text {Color} Text color
/// @param $backgorund {Color} Background color
@mixin wk-product-card-colors($text, $background) {
    @include wk-product-card-label-colors($text, $background);
    @include wk-product-card-button-colors($text, $background);
}

/// Defines the product card label colors
/// @param $text {Color} Text color
/// @param $backgorund {Color} Background color
@mixin wk-product-card-label-colors($text, $background) {
    .wk-product-card-label {
        background-color: $background;
        color: $text;
    }
}

/// Defines the product card button colors
/// @param $text {Color} Text color
/// @param $backgorund {Color} Background color
@mixin wk-product-card-button-colors($text, $background) {
    .wk-product-card-button {
        color: $background;

        &::before {
            background: $background;
            color: $text;
        }
    }
}
