/////
/// Icon header
/// @since 0.1.0
/// @group wk-css-marketing.components.icon-header
/// @author Ricardo Casares
/////

@import 'icon-header.variables';
@import 'icon-header.mixins';
@import 'icon-header.placeholders';

.wk-icon-header {
    @extend %wk-icon-header;
    @include wk-icon-header-colors(wk-primary-color(wkBlue, tint1), wk-primary-color(wkBlue));
}
