/////
/// Hero Carousel
/// @since 0.2.0
/// @group wk-css-marketing.components.hero-carousel
/// @author Andrii Lazurenko
/////

@import 'hero-carousel.variables';
@import 'hero-carousel.placeholders';

.wk-hero-carousel {
    @extend %wk-hero-carousel;
}
