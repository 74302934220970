/////
/// Hero
/// @since 0.2.0
/// @group wk-css-marketing.components.hero
/// @author Andrii Lazurenko
/////

@import 'hero.variables';
@import 'hero.placeholders';

.wk-hero {
    @extend %wk-hero;
}
