/////
/// Featured vacancy
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-vacancy
/// @author Ricardo Casares
/////

@import 'featured-vacancy.variables';

.wk-featured-vacancy {
    font-family: $wk-featured-vacancy-font-family;
    position: relative;

    .wk-featured-vacancy-header {
        color: $wk-featured-vacancy-header-color;
    }

    .wk-featured-vacancy-body {
        color: $wk-featured-vacancy-body-color;
        margin-bottom: $wk-feature-vacancy-body-margin-bottom;
    }

    footer {
        bottom: 0;
        position: relative;
        text-align: right;
    }
}

.wk-featured-vacancy-container {
    @include wk-responsive-grid(
        (
            child: '.wk-featured-vacancy',
            flex: true,
            gutters: $wk-featured-vacancy-container-gutter,
            mobile-count: $wk-featured-vacancy-container-mobile-items,
            tablet-count: $wk-featured-vacancy-container-tablet-items,
            desktop-count: $wk-featured-vacancy-container-desktop-items
        )
    );
}
