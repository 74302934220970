/////
/// Featured contact
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-contact
/// @author Ricardo Casares
/////

@import 'featured-contact.variables';
@import 'featured-contact.mixins';
@import 'featured-contact.placeholders';

.wk-featured-contact {
    @extend %wk-featured-contact;
}

.wk-featured-contact-container {
    @include wk-responsive-grid(
        (
            child: '.wk-featured-contact',
            flex: true,
            gutters: $wk-featured-contact-container-container-gutter,
            mobile-count: $wk-featured-contact-container-mobile-items,
            tablet-count: $wk-featured-contact-container-tablet-items,
            desktop-count: $wk-featured-contact-container-desktop-items
        )
    );

    background-color: $wk-featured-contact-container-bg;
}
