/////
/// Content bar
/// @since 0.1.0
/// @group wk-css-marketing.components.content-bar
/// @author Ron Edgecomb
/////

@import 'content-bar.variables';

.wk-content-bar {
    background-color: $wk-content-bar-background-color;
}

.wk-content-bar-alt {
    background-color: $wk-content-bar-alt-background-color;
}

.wk-content-bar-featured-media {
    background-color: $wk-content-bar-featured-media-background-color;
}
