/////
/// Featured FAQ
/// @since 0.1.0
/// @group wk-css-marketing.components.featured-faq
/// @author Andrii Lazurenko
/////

@import 'featured-faq.variables';
@import 'featured-faq.placeholders';

.wk-featured-faq {
    @extend %wk-featured-faq;
}
