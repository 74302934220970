/////
/// Page Intro
/// @since 0.2.0
/// @group wk-css-marketing.components.page-intro
/// @author Andrii Lazurenko
/////

///Defines properties for Page Intro component
%wk-page-intro {
    background-color: $wk-page-intro-background-color;
    padding: $wk-page-intro-padding;

    @include breakpoint($wk-target-tablet-desktop) {
        padding: $wk-page-intro-padding-tablet-desktop;
    }

    p {
        font-family: $wk-page-intro-p-font-family;
        font-size: $wk-page-intro-p-font-size;
        line-height: $wk-page-intro-p-line-height;
    }
}
