/////
/// Feature carousel
/// @since 0.0.1
/// @group wk-css-marketing.components.feature-carousel
/// @author Ricardo Casares
/////

@import 'feature-carousel.variables';
@import 'feature-carousel.placeholders';
@import 'feature-carousel.mixins';

.wk-feature-carousel {
    @extend %wk-feature-carousel;
    @include wk-feature-carousel-colors($wk-feature-carousel-default-color)
}
