/////
/// Featured Hero Careers
/// @since 0.2.0
/// @group wk-css-marketing.components.featured-hero-careers
/// @author Andrii Lazurenko
/////

%wk-featured-hero-careers {
    .wk-page-intro-container {
        background-color: $wk-featured-hero-careers-page-intro-container-color;

        .wk-page-intro {
            margin: 0 auto;
            max-width: $wk-featured-hero-careers-page-intro-container-max-width;
        }
    }
};
