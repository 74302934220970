/////
/// Mixins
/// @since 0.1.0
/// @group wk-css-marketing.mixins
/// @author Ricardo Casares
/////


/// Creates a Font styles group
///
/// @param {Unit} $font-size.
/// @param {Unit} $line-height.
/// @param {String} $font-weight.
///
/// @example scss - Usage
/// .wk-usp-title {
///   @include wk-font-style(16px, 22px, bold);
/// }
///
/// @example css - Output
/// .usp-title {
///   font-size: 16px;
///   font-weight: bold;
///   line-height: 22px;
/// }
@mixin wk-font-style($font-size: map-get($wk-font-scale, base), $line-height: $wk-body-line-height, $font-weight: map-get($wk-font-weight, regular)) {
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}
